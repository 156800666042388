@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.can {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.15;
}
@layer components {
  html,
  body {
    height: 100%;

    margin: 0;
  }
  body {
    background: #161b22 0% 0% no-repeat padding-box;
    font-family: "Cairo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    /* @apply p-5; */
  }
  #root {
    @apply w-full;
  }

  .bg {
    border: 1px solid #707070;

    @apply h-full;
  }

  .hash-input {
    border: 3px solid #3fa9f5;
    @apply bg-transparent rounded-3xl p-5 outline-none text-white hover:border-2 active:border-2;
  }

  .hash-submit {
    background: #3fa9f5;
    @apply rounded-3xl p-5  text-xl max-w-xs hover:opacity-80 hover:text-white;
  }
  .text-date {
    color: #3fa9f5;
    @apply text-6xl
    /* @apply rounded-3xl p-5 w-1/3 max-w-xs hover:opacity-80 hover:text-white; */;
  }
}

/* body { 
  margin: 0;
  background: #333333 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
